import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=6b52aa09&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=6b52aa09&prod&lang=scss"
import style1 from "./Index.vue?vue&type=style&index=1&id=6b52aa09&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b52aa09",
  null
  
)

export default component.exports