<template>
  <section class="invoice-preview-wrapper">
    <div class="position-relative">
      <b-card class="invoice-preview-card">
        <div class="d-flex justify-content-between flex-wrap">
          <h2 class="my-auto">
            {{ $t('roles.title') }} <br></h2>
          <!-- <b-button
            class="mt-1 float-right my-auto"
            variant="primary"
            @click="createFormulaire"
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Nouvelle fiche</span>
          </b-button> -->
        </div>
      </b-card>

      <b-card class="mt-1">
        <b-table
          class="table table-hover"
          responsive
          :items="listRoles"
          :fields="tableColumns"
          :busy="loadingData"
          show-empty
          :empty-text="$t('table_columns.empty_text')"
          hover
        >
          <!-- Custom Header -->
          <template #head()="{ label, field: { key, sortable } }">
            {{ $t(label) }}
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #cell(id)="data">
            <strong>{{ data.index + 1 }}</strong>
          </template>
          <!-- <template #cell(title)="data">
            {{ data.item.title }}
          </template> -->
          <template #cell(actions)="data">
            <!-- @click="
              $router.push({
                name: 'operateurs-details',
                params: {
                  operateur_id: data.item.id,
                },
              })
              " -->
            <b-button
              variant="primary"
              class="mx-0"
              @click="details(data.item.id, data.item.title)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="text-nowrap"> {{ $t('button.see') }}</span>
            </b-button>
          </template>
        </b-table>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import utilsService from '@/services/utils/utils.service'

import formulaireStoreModule from '@/store/formulaire'
import etablissementsStroreModule from '@/store/etablissements'
import usersStoreModule from '@/store/users'
import rolesStoreModule from '@/store/roles'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { buildFiltersForRequest } from '@/helpers/http-utils'

const mockData = {
  formstatagences: [],
  total: 3,
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    flatPickr,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,

    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    ToastificationContent,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'formulaire', module: formulaireStoreModule },
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'users', module: usersStoreModule },
      { path: 'roles', module: rolesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    const { isEtablissementUser, isAdminEtablissement, isAgentEtablissement } = utilsService.currentUserUtils()

    const departementsList = utilsService.getDepartements()
    const communesList = utilsService.getCommunes()

    return {
      departementsList,
      communesList,
      isAdminEtablissement,
      isAgentEtablissement,
      isEtablissementUser,
      requiredStoreModules,
    }
  },
  data() {
    return {
      listRoles: [],
      loadingData: false,

      tableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true,
          thStyle: { width: '5%' },
        },
        {
          key: 'title',
          label: 'table_columns.title',
          sortable: true,
          thStyle: { width: '20%' },
        },
        /* {
            key: "description",
           label: "table_columns.description",
           sortable: true,
         }, */
        {
          key: 'actions',
          label: 'table_columns.actions',
          thStyle: { width: '15%' },
        },
      ],
    }
  },

  computed: {},

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.loadRoles()
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() { },

  methods: {
    ...mapActions('roles', {
      action_fetchRoles: 'fetchRoles',
    }),
    // ...mapActions("formulaire", {
    //   fetchFormulaires: "fetchFormulaires",
    //   exportFormulaires: "exportFormulaires",
    // }),
    // ...mapActions("etablissements", {
    //   action_findEtablissement: "findEtablissement",
    // }),
    // ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    // ...mapActions("users", {
    //   action_fetchUsers: "fetchUsers",
    //   actions_fetchCommissariats: "fetchCommissariats",
    // }),
    // ----------------------------------------------------------------
    async loadRoles() {
      try {
        this.loadingData = true
        const response = await this.action_fetchRoles()
        console.log('response::: ', response)
        if (response.status === 200) {
          this.listRoles = response.data.data
          this.loadingData = false
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des roles', error)
        this.loadingData = false
      }
    },

    details(id, title) {
      console.log('détails')
      this.$router.push({ name: 'config-roles-datail', params: { id, title } })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";

.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}

.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
